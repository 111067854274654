<template>
  <div>
    <!-- 背景颜色 -->
    <div class="layout-back"></div>
    <!-- 导航 -->
    <NavBar class="nav-bar">
      <div slot="title">退货成功</div>
    </NavBar>
    <div class="layout-card">
      <div class="succeed-card">
        <div class="succeed-prompt">
          <div class="succeed-img-box">
            <img src="~@/assets/common/status_icon@2x.png" />
          </div>
          <span>退货成功</span>
        </div>
        <div class="succeed-time">{{ orderInfo.updated_at }}</div>
      </div>
      <div class="situation-card">
        <div style="font-weight: 600">商家已处理退货申请</div>
        <div class="refund-money">
          <span>退货金额</span>
          <span>￥{{ twoNum(orderInfo.total_money) }}</span>
        </div>
      </div>
      <!-- 退款商品信息 -->
      <div class="content-card">
        <div class="shop-title-left">
          <van-icon name="shop-o" />
          <span class="title-text">供应商：{{ orderInfo.shop_name }}</span>
          <van-icon name="arrow" />
        </div>
        <!-- 商品信息 -->
        <div class="goods-info">
          <div class="goods-images-box">
            <div class="buy-details">
              <div class="goods-img-box">
                <img :src="orderInfo.goods_img" class="goods-img" />
              </div>
              <div class="sku-info">
                <span>{{ orderInfo.goods_name }}</span>
                <div class="specifications">{{ orderInfo.item_key }}</div>
              </div>
            </div>
          </div>
          <div class="price-quantity">
            <div class="price">
              <span>￥{{ integerNum(orderInfo.price) }}</span>
              <span class="price-after">{{ floorNum(orderInfo.price) }}</span>
            </div>
            <div class="quantity">x{{ orderInfo.goods_num }}</div>
          </div>
        </div>
        <!-- 退货原因 -->
        <div class="total-price">
          <div class="layout">
            <span>退货原因：</span>
            <span class="layout-son">{{ orderInfo.return_msg }}</span>
          </div>
          <div class="layout">
            <span>退货金额：</span>
            <span style="color: #e61f18" class="layout-son"
              >￥{{ twoNum(orderInfo.total_money) }}</span
            >
          </div>
          <div class="layout">
            <span>申请时间：</span>
            <span class="layout-son">{{ orderInfo.created_at }}</span>
          </div>
          <div class="layout">
            <span>退款编码：</span>
            <span class="layout-son">{{ orderInfo.return_sn }}</span>
            <div class="copy">复制</div>
          </div>
        </div>
        <div class="service">
          <div class="service-img-box">
            <img src="~@/assets/common/kefu_icon@2x.png" class="service-img" />
          </div>
          <span class="contact">联系客服</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import { getReturnInfo } from "@/api/order";
import tools from "@/common/js/tools";
export default {
  name: "refund-succeed",
  components: {
    NavBar,
  },
  data() {
    return {
      orderId:
        this.$route.query.orderId === undefined ? 0 : this.$route.query.orderId,
      orderInfo: { id: 0 },
    };
  },
  mounted() {
    this.getReturnInfo();
  },
  methods: {
    switchFn(showType) {
      //显示提示弹框
      this.showType = showType;
      switch (showType) {
        case 1:
          this.showMsg = "是否取消此订单";
          break;
        case 2:
          this.showMsg = "确认收到货了吗？";
          break;
        default:
      }
      this.showPopup = true;
    },
    popupFn(bool) {
      // 弹框回调通知
      this.showPopup = false;
      if (bool) {
        switch (this.showType) {
          case 1:
            //执行取消订单操作
            this.cancelOrder();
            break;
          case 2:
            //执行取消订单操作
            this.setDelivery();
            break;
          default:
        }
      }
    },

    integerNum(num) {
      // 获取价格整数部分
      num = this.twoNum(num) + "";
      const result = num.substring(0, num.indexOf(".") - 1);
      return result;
    },
    floorNum(num) {
      // 获取价格小数部分
      return tools.twoFloating(num);
      // num = this.twoNum(num) + "";
      // const result = num.substring(num.indexOf("."));
      // return result;
    },
    twoNum(num) {
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    async getReturnInfo() {
      const ret = await getReturnInfo({ return_id: this.orderId });
      if (ret.code * 1 == 1) {
        this.orderInfo = ret.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.layout-back {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 150px;
  background: linear-gradient(to right, #f9a075, #ff3e46);
  z-index: -99;
}
.nav-bar {
  color: #fff;
}
.layout-card {
  padding: 58px 12px 12px 12px;
}
// 成功提示卡片
.succeed-card {
  background-color: #fff;
  padding: 12px;
  border-radius: 16px;
  .succeed-prompt {
    display: flex;
    align-items: center;
    span {
      font-weight: 600;
    }
    .succeed-img-box {
      width: 20px;
      margin-right: 10px;
      img {
        display: block;
        width: 100%;
      }
    }
  }
  .succeed-time {
    color: #aaaaaa;
    padding-top: 12px;
  }
}
// 处理退款提示
.situation-card {
  background-color: #fff;
  border-radius: 16px;
  padding: 12px;
  margin-top: 12px;
  .refund-money {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
    span {
      &:nth-child(2) {
        color: #e61f18;
      }
    }
  }
}
// 订单卡片样式
.content-card {
  border-radius: 16px;
  padding: 12px;
  background-color: #fff;
  margin-top: 12px;
  .shop-title-left {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #c6c6c6;

    .title-text {
      margin: 0 4px;
      font-weight: 600;
      color: #000;
    }
  }

  .total-price {
    border-bottom: 1px solid #ededed;
    .layout {
      display: flex;
      align-items: center;
      padding: 6px 0;
      .courier-price {
        color: #6f6f6f;
      }
      .layout-son {
        padding-left: 26px;
        color: #6f6f6f;
      }
      .copy {
        margin-left: 10px;
        color: #e61f18;
        border: 1px solid #e61f18;
        border-radius: 10px;
        padding: 2px 6px;
        font-size: 12px;
      }
    }
  }
  .service {
    padding: 12px 12px 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    .service-img-box {
      width: 20px;

      .service-img {
        display: block;
        width: 100%;
      }
    }

    .contact {
      color: #aaaaaa;
      margin-left: 10px;
    }
  }
}
// 商品信息
.goods-info {
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
  .goods-images-box {
    overflow-x: scroll;
  }
  .buy-details {
    display: flex;
    justify-content: center;
    .goods-img-box {
      width: 90px;
      .goods-img {
        width: 100%;
        display: block;
      }
    }

    .sku-info {
      margin-left: 12px;
      font-weight: 600;

      .specifications {
        font-weight: normal;
        color: #6f6f6f;
        padding: 0 16px;
        border-radius: 6px;
        background-color: #f5f5f5;
        margin-top: 12px;
        display: -webkit-box; /**对象作为伸缩盒子模型展示**/
        -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
        -webkit-line-clamp: 2; /**显示的行数**/
        overflow: hidden; /**隐藏超出的内容**/
        word-break: break-all; /** 英语字母设定问题，它不会换行在代码中加入强制换行 可以解决这个问题**/
      }
    }
  }

  .price-quantity {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .price {
      font-size: 18px;
      font-weight: 600;

      .price-after {
        font-size: 14px;
      }
    }

    .quantity {
      margin-top: 12px;
      color: #aaaaaa;
    }
  }
}
</style>
